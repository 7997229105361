import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby";
import mainLogo from "../images/main-logo.svg"

const ListLink = props => (
  <li className="header-list-element">
    <Link className="header-list-link" to={props.to}>
      {props.children}
    </Link>
  </li>
);

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <header className="header-main">
      <nav>
        <ul className="header-ul">
          <ListLink to="/about">ABOUT</ListLink>
          <ListLink to="/manifesto">MANIFESTO</ListLink>
          <ListLink to="/upcoming">UPCOMING</ListLink>
          <ListLink to="/past">PAST</ListLink>
          <ListLink to="/resources">RESOURCES</ListLink>
          <ListLink to="/contact">CONTACT</ListLink>
        </ul>
      </nav>
    </header>
    <main className="index-main">
      <img className="center-img" src={mainLogo} alt="" />
    </main>
  </Layout>
);

export default IndexPage
